<template>
  <div style="margin: 10px">
    <a-button @click="close">关闭</a-button>
    <a-button @click="print" style="margin: 0 20px">打印</a-button>
    <a-button style="float:right">{{this.status[data.status]}}</a-button>
    <h2 style="text-align: center; padding: 10px">销售清单</h2>
    <div style="text-align: left; padding-bottom: 30px" v-if="data.headquarters">
      <a-descriptions title bordered size="small" :column="4">
        <a-descriptions-item label="甲方">{{ data.franchisee.company_name || '' }}</a-descriptions-item>
        <a-descriptions-item label="甲方指定收货单位">{{ data.franchisee.hotel_name }}</a-descriptions-item>
        <a-descriptions-item label="订货日期">{{ data.create_time }}</a-descriptions-item>
        <a-descriptions-item label="订货编号">{{ data.code }}</a-descriptions-item>

        <a-descriptions-item label="乙方">{{ data.headquarters.company_name }}</a-descriptions-item>
        <a-descriptions-item label="联系人">{{ data.headquarters.name + data.headquarters.phone }}</a-descriptions-item>
        <a-descriptions-item label="发票类型">{{ data.headquarters.invoice_type }}</a-descriptions-item>
        <a-descriptions-item label="接收电子发票邮箱">{{ data.franchisee.email }}</a-descriptions-item>

        <a-descriptions-item label="甲方纳税人识别号">{{ data.franchisee.company_code }}</a-descriptions-item>
        <a-descriptions-item label="注册地址、电话">{{ data.franchisee.company_address }}</a-descriptions-item>
        <a-descriptions-item label="开户银行及账号" :span="2">{{ data.franchisee.bank_number }}</a-descriptions-item>

        <a-descriptions-item label="甲方指定交易地址">{{ data.franchisee.shipping_info }}</a-descriptions-item>
        <a-descriptions-item label="指定收货人">{{ data.franchisee.contact }}</a-descriptions-item>
        <a-descriptions-item label="采购人ID" :span="2">{{ data.franchisee.name }}</a-descriptions-item>

        <a-descriptions-item label="乙方开户名称">{{ data.headquarters.bank_account }}</a-descriptions-item>
        <a-descriptions-item label="乙方地址">{{ data.headquarters.company_address }}</a-descriptions-item>
        <a-descriptions-item label="开户银行及账号" :span="2">{{ data.headquarters.bank_number }}</a-descriptions-item>

        <!-- <a-descriptions-item label="物流公司">{{ data. }}</a-descriptions-item>
        <a-descriptions-item label="物流单号">{{ data. }}</a-descriptions-item>
        <a-descriptions-item label="物流电话">{{ data. }}</a-descriptions-item>-->
      </a-descriptions>
      <!-- 订单备注 -->
      <div style="text-align: left; margin: 20px 0">
        <a-descriptions bordered layout="vertical" :column="1">
          <a-descriptions-item label="订单附注说明及产品明细">
            <ol style="padding: 0 15px">
              <li>甲方请核对《销售清单》附表所列产品与“舒悦商城”上提交的产品是否一致。</li>
              <li>乙方为供应商提供代销商业服务，仅办理订单管理、货款结算等业务，不负责售后及其他合同义务。</li>
              <li>在甲方将本订单全额款项汇至乙方指定银行账户后，供应商将产品送至本销售单甲方指定的交货地址(运费负担情况见下运费说明)。</li>
              <li>下表所列产品在供应商送到后必须由甲方指定收货人签收，非指定人员签收无效，供应商有权不予交货。</li>
              <li>因自然灾害、政府行为等不能预见、不能避免并且不能克服的客观情况致使供应商不能按时交货的，不视为供应商违约，乙方及供应商无需就此承担责任；</li>
              <li>合同未作约定或约定不明的，甲、乙双方同意按舒悦规则及相关规定协商调解。</li>
            </ol>
          </a-descriptions-item>
        </a-descriptions>
      </div>
      <!-- 清单明细 -->
      <div
        style="text-align: left; margin: 20px 0"
        v-for="(carts, index) in data.carts"
        :key="index"
      >
        <a-table
          bordered
          :data-source="carts.cart"
          :pagination="false"
          :row-key="(record) => record.id_order_cart"
          :columns="[
            { title: '产品名称', dataIndex: 'name',align:'center', customRender: (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 8,
                },
              }
            } },
            { title: '产品板块', dataIndex: 'category1',align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              }
            } },
            { title: '产品大类', dataIndex: 'category2',align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              }
            } },
            { title: '产品小类', dataIndex: 'category3',align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              }
            }  },
            { title: '规格说明', dataIndex: 'guide_format', width: 300,align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              }
            }  },
            { title: '采购单位', dataIndex: 'product_unit',align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              }
            }  },
            { title: '采购数量', dataIndex: 'amount',align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              }
            }  },
            { title: '单价', dataIndex: 'price_settle',align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 0,
                },
              }
            } },
            { title: '金额', dataIndex: 'order_money',align:'center',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 1,
                },
              }
            }  },
            { title: '备注', dataIndex: 'note',customRender:  (text, row, index)=>{
              if (index!==carts.cart.length-1){
                return text
              }
              return {
                children: text,
                attrs: {
                  colSpan: 1,
                },
              }
            }  },
          ]"
        >
          <template slot="footer">
            <!-- <div style="text-align: left">金额小计：￥{{ carts.total_order_money }}</div>
            <div style="text-align: left">商品件数：{{ carts.cart.length }}</div>-->
            <div style="text-align: left">运费描述：{{ carts.supplier.freight_note || '暂无运费描述' }}</div>
          </template>
        </a-table>
      </div>
      <!-- 清单总计 -->
      <!-- <div style="text-align: left">
        <a-descriptions bordered layout="vertical" :column="5">
          <a-descriptions-item
            :key="i.label"
            :label="i.label"
            v-for="i in [
              { label: '支付方式', value: data.payment_method === 0 ? '线下支付' : '其它' },
              { label: '金额总计', value: '￥' + parseFloat(data.order_money).toFixed(2) },
              { label: '折扣', value: (data.discount*100).toFixed(2)+'%' },
              { label: '优惠后金额', value: '￥' + parseFloat(data.real_money).toFixed(2) },
              { label: '门店实付金额', value: '￥' + parseFloat(data.pay_money).toFixed(2) },
            ]"
          >
            <div>{{ i.value }}</div>
          </a-descriptions-item>
        </a-descriptions>
      </div>-->
    </div>
    <div v-else style="margin: 0 10px">
      <a-divider style="margin: 10px 0" />
      <a-skeleton active :paragraph="{ rows: 7 }" />
      <a-divider style="margin: 10px 0" />
      <a-skeleton active :paragraph="{ rows: 5 }" />
      <a-skeleton active :paragraph="{ rows: 2 }" />
      <a-divider style="margin: 10px 0" />
      <a-skeleton active :paragraph="{ rows: 20 }" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      status: { 1: '未下单', 2: '未支付', 3: '待确认', 4: '备货中（未完全支付）', 5: '已支付', 6: '取消', 7: '待收货', 8: '已收货', 9: '退货待供应商确认', 10: '退货待加盟商发货', 11: '退货待供应商收货', 12: '待退款', 13: '已退货待确认', 14: '退货失败', 15: '退货成功' },
      data: {
      }
    }
  },
  methods: {
    close() {
      window.close()
    },
    print() {
      this.$info({
        title: '打印销售清单',
        content: '为保证显示正常，建议选择横向纸张打印',
        okText: '打印',
        onOk: () => {
          setTimeout(() => {
            window.print()
          }, 600)
        }
      })
    }
  },
  mounted() {
    const code = this.$route.query.contract
    const supplier = this.$route.query.supplier
    this.get('getSupplierMasterForFranchisee', {
      is_detail: true,
      code: code,
      id_order_master: code,
      id_user_supplier: supplier
    }).then((res) => {
      res.franchisee = this.getUser()
      this.data = res
    })
  }
}
</script>
